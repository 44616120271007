/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React from 'react'
import { API } from 'aws-amplify'
import gql from 'graphql-tag'
import * as mutations from '../graphql/mutations'
import { breakpoints, getPictureUrl } from '../lib/util'
import useReducer from '../reducers'

const classes = {
  slide: css`
    min-height: 210px;
    max-height: 210px;
    height: 210px;
    --webkit-overflow-scrolling: touch;

    ${breakpoints.xxs} {
      min-height: 215px;
      max-height: 215px;
      height: 215px;
    }
  `
}

const Slide = ({ slide, history, path, delivery, fromSite }) => {
  const [
    {
      sliderReducer: { client, cdn }
    }
  ] = useReducer()
  const picture = (slide && slide.file) || {}
  const pictureUrl = getPictureUrl({ ...picture, size: '640x0', cdn })

  const x = React.useRef()

  const handleClick = (e) => {
    const delta = Math.abs(e.screenX - x.current)

    if (delta > 10) {
      // If mouse moved more than threshold, ignore the click event
      e.preventDefault()
    } else {
      console.log('click')
      handleSlideClick(slide)
    }

    x.current = 0
  }

  const handleMouseDown = (e) => {
    // store mouse position when click starts
    x.current = e.screenX
  }

  const handleSlideClick = (slide) => {
    console.log('onClic slide', slide)
    const apiName = 'api616cdc2a'
    const APIpath = '/browserData'
    const now = new Date()

    API.get(apiName, APIpath)
      .then((browserData) => {
        return client.mutate({
          mutation: gql(mutations.createPromoLog),
          variables: {
            input: {
              serviceID: slide.id,
              service: 'SLIDE',
              type: 'CLIC',
              analytics:
                browserData && browserData.analytics
                  ? JSON.stringify(browserData.analytics)
                  : null,
              hit: slide.clics + 1,
              createdAt: now.toISOString()
            }
          }
        })
      })

      .then((response) => {
        console.log('create log response', response)
        return response
      })
      .catch((error) => {
        console.log('error', error)
      })

    client
      .mutate({
        mutation: gql(mutations.updateSlide),
        variables: {
          input: {
            id: slide.id,
            clics: slide.clics + 1,
            _version: slide._version
          }
        }
      })
      .then((response) => {
        console.log('response', response)
        return response
      })
      .catch((error) => {
        console.log('error', error)
      })

    if (slide.url) {
      const parsedUrl = delivery
        ? fromSite
          ? `${path}/${slide.url}`
          : `${path}/${slide.url}`
        : `${path}/${slide.url}`

      console.log('parsedUrl', parsedUrl)

      history.push(parsedUrl)
    }
  }

  return (
    <div
      css={classes.slide}
      style={{
        ...{ position: 'relative' },
        ...(pictureUrl
          ? { background: `url(${pictureUrl})`, backgroundSize: 'cover' }
          : {})
      }}
    >
      <div
        role='button'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          cursor: 'pointer'
        }}
        onMouseDown={handleMouseDown}
        onClick={handleClick}
      />
    </div>
  )
}

export default Slide
