const initialState = {
  client: null,
  cdn: null,
  slides: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SLIDER_SET_CLIENT':
      return {
        ...state,
        client: action.payload.client
      }
    case 'SLIDER_SET_CDN':
      return {
        ...state,
        cdn: action.payload.cdn
      }
    case 'SLIDER_SET_SLIDES':
      return {
        ...state,
        slides: action.payload.slides
      }
    default:
      return state
  }
}

export default { initialState, reducer }
