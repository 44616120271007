import React from 'react'

const Select = ({ id, label = '', options, hasError, helperText, ...rest }) => {
  return (
    <div className='mb-4'>
      <label
        htmlFor={id}
        className='block text-sm leading-5 font-medium text-gray-700'
      >
        {label}
      </label>
      <select
        id={id}
        className={`${
          !rest.value ? 'text-gray-500' : ''
        } mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 ${
          hasError
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red'
            : 'border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300'
        }  sm:text-sm sm:leading-5 disabled:opacity-50`}
        {...rest}
      >
        <option value='' className='text-gray-50'>
          Selecciona
        </option>
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          )
        })}
      </select>
      {helperText ? (
        <p
          className={`mt-2 text-sm ${hasError ? 'text-red-600' : null}`}
          id='email-error'
        >
          {helperText}
        </p>
      ) : null}
    </div>
  )
}

export default Select
