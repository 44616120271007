import React from 'react'

const HappyIcon = () => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      style={{ enableBackground: 'new 0 0 512 512' }}
    >
      <circle style={{ fill: '#FFD93B' }} cx='256' cy='256' r='256' />
      <path
        style={{ fill: '#F4C534' }}
        d='M512,256c0,141.44-114.64,256-256,256c-80.48,0-152.32-37.12-199.28-95.28
	c43.92,35.52,99.84,56.72,160.72,56.72c141.36,0,256-114.56,256-256c0-60.88-21.2-116.8-56.72-160.72
	C474.8,103.68,512,175.52,512,256z'
      />
      <circle style={{ fill: '#3E4347' }} cx='350.176' cy='225.6' r='40.176' />
      <ellipse
        transform='matrix(-0.9141 -0.4054 0.4054 -0.9141 607.8411 544.3153)'
        style={{ fill: '#5A5F63' }}
        cx='361.564'
        cy='207.787'
        rx='12.16'
        ry='7.872'
      />
      <circle style={{ fill: '#3E4347' }} cx='161.824' cy='225.6' r='40.176' />
      <ellipse
        transform='matrix(-0.9141 -0.4054 0.4054 -0.9141 247.3004 467.9457)'
        style={{ fill: '#5A5F63' }}
        cx='173.206'
        cy='207.784'
        rx='12.16'
        ry='7.872'
      />
      <path
        style={{ fill: '#3E4347' }}
        d='M151.136,357.056c-4.832-4.544-5.056-12.144-0.512-16.96c4.544-4.816,12.128-5.056,16.96-0.512
	c49.584,46.672,127.248,46.672,176.832,0c4.832-4.544,12.416-4.304,16.96,0.512c4.544,4.832,4.32,12.416-0.512,16.96
	C302.064,412.416,209.936,412.416,151.136,357.056z'
      />
    </svg>
  )
}

export default HappyIcon
