import React from 'react'

import RatingField from '../RatingField'
import TextareaField from '../TextareaField'
import InputField from '../InputField'
import Select from '../Select'
import MultiSelect from '../MultiSelect'

const FieldSelector = ({ question, formik }) => {
  console.log('question', question)
  const selectOptions = question?.options?.items
    ? question?.options?.items.map((o) => ({
        label: o.title,
        value: o.value
      }))
    : []

  const { range = [1, 5], icon = 'star' } = question?.options?.items?.[0] || {
    range: [1, 5],
    icon: 'star'
  }

  const getField = () => {
    switch (question.questionType) {
      case 'textarea':
        return (
          <TextareaField
            id={question.id}
            name={question.id}
            label={question.title}
            placeholder={question.description}
            hasError={formik.errors[question.id] && formik.touched[question.id]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[question.id]}
            helperText={
              formik.errors[question.id] && formik.touched[question.id]
                ? formik.errors[question.id]
                : null
            }
          />
        )
      case 'rating':
        return (
          <RatingField
            label={question.title}
            description={question.description}
            maxRange={range?.[1]}
            icon={icon}
            color='yellow'
            value={formik.values[question.id]}
            onChange={(value) => {
              formik.setFieldValue(question.id, value)
            }}
            hasError={formik.errors[question.id] && formik.touched[question.id]}
            helperText={
              formik.errors[question.id] && formik.touched[question.id]
                ? formik.errors[question.id]
                : null
            }
          />
        )
      case 'select':
        return (
          <Select
            id={question.id}
            name={question.id}
            options={selectOptions}
            label={question.title}
            placeholder={question.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[question.id]}
            hasError={formik.errors[question.id] && formik.touched[question.id]}
            helperText={
              formik.errors[question.id] && formik.touched[question.id]
                ? formik.errors[question.id]
                : null
            }
            disabled={!(selectOptions && selectOptions.length)}
          />
        )
      case 'multiselect':
        return (
          <MultiSelect
            formik={formik}
            name={question.id}
            label={question.title}
            description={question.description}
            options={selectOptions}
          />
        )
      default:
        return (
          <InputField
            id={question.id}
            name={question.id}
            label={question.title}
            placeholder={question.description}
            type='text'
            autoComplete='off'
            hasError={formik.errors[question.id] && formik.touched[question.id]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[question.id]}
            helperText={
              formik.errors[question.id] && formik.touched[question.id]
                ? formik.errors[question.id]
                : null
            }
          />
        )
    }
  }

  return getField()
}

export default FieldSelector
