import React from 'react'

const SadIcon = () => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      style={{ enableBackground: 'new 0 0 512 512' }}
    >
      <circle style={{ fill: '#FFD93B' }} cx='256' cy='256' r='256' />
      <path
        style={{ fill: '#F4C534' }}
        d='M512,256c0,141.44-114.64,256-256,256c-80.48,0-152.32-37.12-199.28-95.28
	c43.92,35.52,99.84,56.72,160.72,56.72c141.36,0,256-114.56,256-256c0-60.88-21.2-116.8-56.72-160.72
	C474.8,103.68,512,175.52,512,256z'
      />
      <g>
        <path
          style={{ fill: '#3E4347' }}
          d='M170.864,404.24c-7.344-4.912-9.312-14.848-4.4-22.192c37.056-55.36,94.24-55.36,173.392-55.36
		c8.832,0,16,7.168,16,16s-7.168,16-16,16c-80.528,0-120.416,1.744-146.8,41.168C188.112,407.216,178.176,409.12,170.864,404.24z'
        />
        <circle
          style={{ fill: '#3E4347' }}
          cx='163.2'
          cy='213.712'
          r='41.056'
        />
      </g>
      <path
        style={{ fill: '#5A5F63' }}
        d='M186.416,206.576c-2.848,2.688-8.224,1.712-11.664-2.112c-3.552-3.808-4.16-9.216-1.168-11.824
	c2.96-2.72,8.208-1.824,11.76,2C188.912,198.56,189.376,203.872,186.416,206.576z'
      />
      <circle
        style={{ fill: '#3E4347' }}
        cx='348.864'
        cy='213.712'
        r='41.056'
      />
      <path
        style={{ fill: '#5A5F63' }}
        d='M372.08,206.576c-2.848,2.688-8.224,1.712-11.664-2.112c-3.552-3.808-4.16-9.216-1.168-11.824
	c2.96-2.72,8.208-1.824,11.76,2C374.576,198.56,375.04,203.872,372.08,206.576z'
      />
    </svg>
  )
}

export default SadIcon
