export const createResponse = /* GraphQL */ `
  mutation CreateResponse(
    $input: CreateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    createResponse(input: $input, condition: $condition) {
      id
      restaurantID
      surveyID
      orderID
      waiter
      userID
      email
      phoneNumber
      values
      reward
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      survey {
        id
        restaurantID
        title
        description
        slug
        activateRewards
        public
        rewardPlace
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        steps {
          nextToken
          startedAt
        }
        questions {
          nextToken
          startedAt
        }
        responses {
          nextToken
          startedAt
        }
      }
    }
  }
`
