/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React from 'react'
import { API } from 'aws-amplify'
import gql from 'graphql-tag'
import SwipeableViews from 'react-swipeable-views'
import autoPlay from 'react-swipeable-views-utils/lib/autoPlay'
// import Pagination from 'docs/src/modules/components/Pagination'

import { breakpoints } from '../lib/util'
import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'
import Slide from './Slide'
import useReducer from '../reducers'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const classes = {
  root: css`
    padding-right: 26px;
    max-width: 414px;
  `,
  slide: css`
    min-height: 210px;
    max-height: 210px;
    height: 210px;
    color: #fff;
    --webkit-overflow-scrolling: touch;

    ${breakpoints.xxs} {
      min-height: 215px;
      max-height: 215px;
      height: 215px;
    }
  `
}

const styles = {
  container: {
    margin: '0px 0px'
  },
  slide1: {
    background: '#ddd'
  }
}

const Slider = ({ restaurantID, history, path, delivery, fromSite }) => {
  const [
    {
      sliderReducer: { client, slides }
    },
    dispatch
  ] = useReducer()
  const [loading, setLoading] = React.useState(true)
  const [currSlide, setCurrSlide] = React.useState(0)

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      let filteredSlides = []

      try {
        const { data } = await client.query({
          query: gql(queries.listSlides),
          fetchPolicy: 'network-only',
          variables: {
            filter: {
              restaurantID: { eq: restaurantID }
            }
          }
        })
        console.log('slides', data)
        const { listSlides } = data || {}
        filteredSlides = listSlides?.items.filter((x) => !x._deleted)
        dispatch({
          type: 'SLIDER_SET_SLIDES',
          payload: { slides: filteredSlides }
        })
      } catch (error) {
        console.log('listslides error', error)
        setLoading(false)
        return
      }

      const apiName = 'api616cdc2a'
      const path = '/browserData'
      let browserData

      try {
        const result = await API.get(apiName, path)
        console.log('browserData result', result)
        browserData = result
      } catch (error) {
        console.log('browserData error', error)
      }

      if (filteredSlides && filteredSlides.length) {
        const now = new Date()
        Promise.all(
          filteredSlides.map((slide) => {
            // crear logs
            client
              .mutate({
                mutation: gql(mutations.createPromoLog),
                variables: {
                  input: {
                    serviceID: slide.id,
                    service: 'SLIDE',
                    type: 'VIEW',
                    analytics:
                      browserData && browserData.analytics
                        ? JSON.stringify(browserData.analytics)
                        : null,
                    hit: slide.views + 1,
                    createdAt: now.toISOString()
                  }
                }
              })
              .then((response) => {
                console.log('create log response', response)
                return response
              })
              .catch((error) => {
                console.log('create log error', error)
              })

            // actualizar views de los slides
            return client
              .mutate({
                mutation: gql(mutations.updateSlide),
                variables: {
                  input: {
                    id: slide.id,
                    views: slide.views + 1,
                    _version: slide._version
                  }
                }
              })
              .then((response) => {
                console.log('response', response)
                return response
              })
              .catch((error) => {
                console.log('error', error)
              })
          })
        )
          .then((response) => {
            console.log('update slides response', response)

            return client.query({
              query: gql(queries.listSlides),
              fetchPolicy: 'network-only',
              variables: {
                filter: {
                  restaurantID: { eq: restaurantID }
                }
              }
            })
          })
          .then(({ data }) => {
            const { listSlides } = data || {}
            dispatch({
              type: 'SLIDER_SET_SLIDES',
              payload: { slides: listSlides?.items.filter((x) => !x._deleted) }
            })
          })
          .catch((error) => {
            console.log('update slides error', error)
          })
      }
      setLoading(false)
    }

    if (restaurantID) {
      fetchData()
    }
  }, [])

  const handleChangeIndex = (index) => {
    setCurrSlide(index)
  }

  return loading ? (
    <div css={classes.slide} style={Object.assign({}, styles.slide1)} />
  ) : slides && slides.length ? (
    <div style={{ position: 'relative' }}>
      <AutoPlaySwipeableViews
        enableMouseEvents
        resistance
        css={classes.root}
        containerStyle={styles.container}
        interval={4500}
        index={currSlide}
        onChangeIndex={handleChangeIndex}
      >
        {slides && slides.length ? (
          slides.map((slide) => {
            return (
              <Slide
                key={slide.id}
                slide={slide}
                restaurantID={restaurantID}
                history={history}
                path={path}
                delivery={delivery}
                fromSite={fromSite}
              />
            )
          })
        ) : (
          <div css={classes.slide} style={Object.assign({}, styles.slide1)}>
            <div style={{ padding: 16 }}>slide n°4</div>
          </div>
        )}
      </AutoPlaySwipeableViews>
    </div>
  ) : null
}

export default Slider
