import React from 'react'
import PropTypes from 'prop-types'
import { Reducer } from '../reducers'
import Wrapper from './Wrapper'

const SurveyProvider = ({ awsConfig, options, children }) => {
  return (
    <Reducer>
      <Wrapper awsConfig={awsConfig} options={options}>
        {children}
      </Wrapper>
    </Reducer>
  )
}

SurveyProvider.propTypes = {
  awsConfig: PropTypes.object.isRequired,
  options: PropTypes.object,
  children: PropTypes.element.isRequired
}

export default SurveyProvider
