import React from 'react'

const SurveySuccess = ({ setSent }) => {
  return (
    <div className='flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
      {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
      {/* <span className='hidden sm:inline-block sm:align-middle sm:h-screen' />
      &#8203; */}
      <div
        className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6'
        role='dialog'
        aria-modal='true'
        aria-labelledby='modal-headline'
      >
        <div>
          <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
            {/* <!-- Heroicon name: check --> */}
            <svg
              className='h-6 w-6 text-green-600'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M5 13l4 4L19 7'
              />
            </svg>
          </div>
          <div className='mt-3 text-center sm:mt-5'>
            <h3
              className='text-lg leading-6 font-medium text-gray-900'
              id='modal-headline'
            >
              ¡Muchas gracias!
            </h3>
            <div className='mt-2'>
              <p className='text-sm leading-5 text-gray-500'>
                Tus comentarios son muy importantes para ofrecer el servicio que
                mereces.
              </p>
            </div>
          </div>
        </div>
        <div className='mt-5 sm:mt-6'>
          <span className='flex w-full rounded-md shadow-sm'>
            <button
              type='button'
              className='inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-orange-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange transition ease-in-out duration-150 sm:text-sm sm:leading-5'
              onClick={() => setSent(false)}
            >
              Realizar nueva encuesta
            </button>
          </span>
        </div>
      </div>
    </div>
  )
}

export default SurveySuccess
