import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import moment from 'moment'

import DotsPattern from './Assets/DotsPattern'
import * as queries from '../graphql/queries'
import useReducer from '../reducers'
import SurveyForm from './Survey/SurveyForm'
import SurveySuccess from './Survey/SurveySuccess'
import { getStepSchema } from '../lib/util'
import Loading from './Loading'
import Alert from './Common/Alert'

const Survey = ({ restaurantID, slug, restLogo, restName }) => {
  const [
    {
      surveyReducer: { client, survey }
    },
    dispatch
  ] = useReducer()
  const [loading, setLoading] = React.useState(true)
  const [wasSent, setSent] = React.useState(false)

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      try {
        const { data } = await client.query({
          query: gql(queries.surveybyRestaurantbySlug),
          fetchPolicy: 'network-only',
          variables: {
            restaurantID,
            slug: { eq: slug },
            filter: { status: { ne: 'DELETED' } }
          }
        })
        const { surveybyRestaurantbySlug: { items: [item] } = { items: [] } } =
          data || {}

        if (item && ['PUBLISHED', 'DRAFT'].includes(item.status)) {
          console.log('survey', item)
          dispatch({ type: 'SURVEY_SET', payload: { survey: item } })
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setLoading(false)
      }
    }

    if (restaurantID && slug) {
      fetchData()
    }
  }, [])

  if (loading) {
    return <Loading />
  }

  let initialValues = {}
  // eslint-disable-next-line no-unused-expressions
  survey?.steps?.items.forEach((step) => {
    // eslint-disable-next-line no-unused-expressions
    step?.questions?.items.forEach((q) => {
      initialValues = {
        ...initialValues,
        [q.id]: q.questionType !== 'multiselect' ? '' : []
      }
    })
  })

  const stepsSchemas = (survey?.steps?.items || []).map((step) => {
    return getStepSchema(step?.questions?.items)
  })

  return (
    <div className='min-h-screen bg-gradient-to-r from-red-600 via-orange-400 to-yellow-300 py-16 px-4 overflow-x-hidden sm:px-6 lg:px-8 lg:py-22'>
      <div className='flex-1 flex flex-col relative max-w-xl mx-auto'>
        <DotsPattern />
        <DotsPattern className='absolute right-full bottom-0 transform -translate-x-1/2' />
        {restLogo ? (
          <img
            className='h-16 self-center rounded lg:h-20 mb-2'
            src={restLogo}
            alt={restName + ' logo'}
          />
        ) : null}

        {!wasSent ? (
          <div className='text-cool-gray-700 inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all'>
            {!survey ? (
              <Alert message='La encuesta solicitada no existe o no esta disponible.' />
            ) : (
              <React.Fragment>
                <div className='text-center'>
                  <h2 className='text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
                    {survey?.title}
                  </h2>
                  <p className='mt-4 text-lg leading-6 text-gray-500'>
                    {survey?.description ||
                      'Tu opinión es muy importante para nosotros.'}
                  </p>
                </div>
                <div className='mt-12'>
                  <SurveyForm
                    surveyID={survey?.id}
                    initialValues={initialValues}
                    schemas={stepsSchemas}
                    steps={survey?.steps?.items || []}
                    setSent={setSent}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        ) : (
          <SurveySuccess setSent={setSent} />
        )}
      </div>
      <p className='mt-8 text-center text-base leading-6 text-gray-100'>
        Desarrollado por{' '}
        <a
          href='https://landing.2dine.io'
          target='_blank'
          rel='noreferrer'
          className='text-base leading-6 text-gray-100 hover:text-gray-200'
        >
          2dine
        </a>{' '}
        para {restName}
      </p>
      <p className='mt-1 text-center text-base leading-6 text-gray-100'>
        &copy; {moment().format('YYYY')} 2dine, Inc. Todos los derechos
        reservados.
      </p>
    </div>
  )
}

Survey.propTypes = {
  restaurantID: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired
}

export default Survey
