import React from 'react'
import _orderBy from 'lodash/orderBy'
import _flattenDeep from 'lodash/flattenDeep'
import { useFormik } from 'formik'
import gql from 'graphql-tag'

import Button from '../Common/Button'
import FieldSelector from './FieldSelector'
import useReducer from '../../reducers'
import * as mutations from '../../graphql/mutations'
import { cleanObj } from '../../lib/util'

const SurveyForm = ({ surveyID, initialValues, schemas, steps, setSent }) => {
  const [
    {
      surveyReducer: { client, survey }
    }
  ] = useReducer()
  const [loading, setLoading] = React.useState(false)
  const [currentStep, setCurrentStep] = React.useState(0)
  const isLastStep = currentStep + 1 === (steps && steps.length)
  const now = new Date()

  const formik = useFormik({
    initialValues,
    validationSchema: schemas[currentStep],
    onSubmit: async (values, actions) => {
      console.log('values', values)

      if (!isLastStep) {
        setCurrentStep((state) => state + 1)
      } else {
        if (!survey || survey.status !== 'PUBLISHED') {
          window.alert('La encuesta no recibe respuestas en este momento')
          actions.resetForm()
          setSent(false)
          setCurrentStep(0)
          return
        }

        setLoading(true)

        const formQuestions = _flattenDeep(
          steps.map((s) => {
            return s?.questions?.items
          })
        )

        /**
         * { questionID: { question, values } }
         */
        const inputValues = { ...values }
        cleanObj(inputValues)
        for (const key in inputValues) {
          const question = formQuestions.find((x) => x.id === key)
          inputValues[key] = {
            question,
            value: inputValues[key]
          }
        }

        try {
          const response = await client.mutate({
            mutation: gql(mutations.createResponse),
            variables: {
              input: {
                surveyID,
                values: JSON.stringify(inputValues),
                createdAt: now.toISOString()
              }
            }
          })
          console.log('response', response)
          setLoading(false)
          setSent(true)
        } catch (error) {
          console.log('createResponse error', error)
          setLoading(false)
        }
      }
    }
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'
    >
      <div className='sm:col-span-2'>
        {_orderBy(
          steps[currentStep]?.questions?.items || [],
          ['order'],
          ['asc']
        ).map((q) => {
          return (
            <div key={q.id}>
              <FieldSelector formik={formik} question={q} />
            </div>
          )
        })}
      </div>
      <div className='sm:col-span-2'>
        <Button
          type='submit'
          color='orange'
          size='xl'
          fullWidth
          loading={loading}
          disabled={loading}
        >
          {isLastStep ? 'Enviar' : 'Siguiente'}
        </Button>
      </div>
    </form>
  )
}

export default SurveyForm
