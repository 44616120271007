import * as yup from 'yup'

const cleanObj = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName]
    }
  }
}

const getPictureUrl = ({ key, level = 'public', size = '300x300', cdn }) => {
  if (key) {
    const parsedKeyArr = key.split('/')
    parsedKeyArr.splice(parsedKeyArr.length - 1, 0, size)
    const parsedKey = parsedKeyArr.join('/')
    return `${cdn}/${level}/${parsedKey}`
  }
  return null
}

const getStepSchema = (questions) => {
  if (questions && questions.length) {
    let schemaDefinition = {}

    questions.map((q) => {
      switch (q.questionType) {
        case 'multiselect':
          schemaDefinition = {
            ...schemaDefinition,
            [q.id]: yup.array(yup.string())
          }
          break
        case 'rating':
          schemaDefinition = {
            ...schemaDefinition,
            [q.id]: yup.number().required().positive().integer()
          }
          break
        default:
          schemaDefinition = { ...schemaDefinition, [q.id]: yup.string() }
          break
      }
    })

    const schema = yup.object().shape(schemaDefinition)

    return schema
  }

  return null
}

export { cleanObj, getPictureUrl, getStepSchema }
