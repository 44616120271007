import React from 'react'
import loadingReducer from './loadingReducer'
import surveyReducer from './surveyReducer'
import { combineReducers } from '../lib/combineReducers'

const Context = React.createContext()

const [rootReducer, rootState] = combineReducers({
  loadingReducer: [loadingReducer.reducer, loadingReducer.initialState],
  surveyReducer: [surveyReducer.reducer, surveyReducer.initialState]
})

export const Reducer = ({ children }) => {
  const mainReducer = React.useReducer(rootReducer, rootState)

  return <Context.Provider value={mainReducer}>{children}</Context.Provider>
}

const useReducer = () => React.useContext(Context)
export default useReducer
