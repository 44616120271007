import React from 'react'

const ButtonBase = ({ children, ...props }) => {
  return <button {...props}>{children}</button>
}

const Button = ({
  disabled = false,
  children = 'Button text',
  loading = false,
  color = 'blue',
  size = 'md', // xs sm md lg xl
  variant = 'primary', // primary, secondary, white
  Icon = null,
  iconPosition = 'leading', // leading, trailing
  fullWidth = false,
  as = null,
  ...rest
}) => {
  const sizeClasses = {
    xs: 'px-2.5 py-1.5 text-xs leading-4',
    sm: 'px-3 py-2 text-sm leading-4',
    md: 'px-4 py-2 text-sm leading-5',
    lg: 'px-4 py-2 text-base leading-6',
    xl: 'px-6 py-3 text-base leading-6'
  }

  const iconSizeClasses = {
    xs: 'h-4 w-4 -ml-0.5 mr-2',
    sm: 'h-5 w-5 -ml-1 mr-2',
    md: 'h-5 w-5 -ml-1 mr-3',
    lg: 'h-5 w-5 -ml-1 mr-3',
    xl: 'h-5 w-5 -ml-1 mr-3'
  }

  const iconSizeTrailingClasses = {
    xs: 'h-4 w-4 ml-2 -mr-0.5',
    sm: 'h-5 w-5 ml-2 -mr-1',
    md: 'h-5 w-5 ml-3 -mr-1',
    lg: 'h-5 w-5 ml-3 -mr-1',
    xl: 'h-5 w-5 ml-3 -mr-1'
  }

  const variantClasses = {
    primary: `border-transparent text-white bg-${color}-600 ${
      !disabled ? `hover:bg-${color}-500` : ''
    } focus:outline-none focus:border-${color}-700 focus:shadow-outline-${color} active:bg-${color}-700`,
    secondary: `border-transparent text-${color}-700 bg-${color}-100 ${
      !disabled ? `hover:bg-${color}-50` : ''
    } focus:outline-none focus:border-${color}-300 focus:shadow-outline-${color} active:bg-${color}-200`,
    white: `border-gray-300 text-gray-700 bg-white ${
      !disabled ? `hover:text-gray-500` : ''
    }  focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50`,
    outlined: `relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white ${
      !disabled ? 'hover:text-cool-gray-500' : ''
    } focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition ease-in-out duration-150`
  }

  const Component = as || ButtonBase

  return (
    <span
      className={`${
        fullWidth ? 'block w-full' : 'inline-flex'
      } rounded-md shadow-sm`}
    >
      <Component
        className={`${
          fullWidth ? 'w-full flex justify-center' : 'inline-flex items-center'
        } ${sizeClasses[size]} font-medium rounded-md border ${
          variantClasses[variant]
        } transition ease-in-out duration-150 disabled:opacity-75 disabled:cursor-not-allowed`}
        disabled={disabled}
        {...rest}
      >
        {loading ? (
          <svg
            className={`animate-spin ${iconSizeClasses[size]}`}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            />
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            />
          </svg>
        ) : Icon && iconPosition === 'leading' ? (
          <Icon className={iconSizeClasses[size]} />
        ) : null}
        {children}
        {!loading && Icon && iconPosition === 'trailing' ? (
          <Icon className={iconSizeTrailingClasses[size]} />
        ) : null}
      </Component>
    </span>
  )
}

export default Button
