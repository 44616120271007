import React from 'react'

const ErrorIcon = () => {
  return (
    <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
      <svg
        className='h-5 w-5 text-red-500'
        fill='currentColor'
        viewBox='0 0 20 20'
      >
        <path
          fillRule='evenodd'
          d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  )
}

const InputBase = ({
  icon,
  id,
  name,
  extraButton,
  hasError,
  placeholder,
  label,
  Trailing,
  ...rest
}) => {
  return (
    <React.Fragment>
      {icon && (
        <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
          {icon}
        </div>
      )}
      <input
        id={id}
        name={name}
        className={`form-input block w-full pr-12 sm:text-sm sm:leading-5 ${
          extraButton ? 'rounded-none rounded-l-md' : ''
        } ${
          hasError
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red'
            : null
        } ${icon ? 'pl-10' : ''}`}
        placeholder={placeholder || label}
        aria-invalid='true'
        {...rest}
      />
      {hasError ? <ErrorIcon /> : null}

      {!hasError && Trailing}
    </React.Fragment>
  )
}

const InputField = ({
  id,
  name,
  label,
  placeholder,
  helperText,
  hasError,
  icon,
  extraButton = null,
  Trailing,
  labelOnLeft = false,
  ...rest
}) => {
  return (
    <div className='mb-4'>
      {labelOnLeft ? (
        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
          <label
            htmlFor={id}
            className='block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2'
          >
            {label}
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div className='max-w-lg flex rounded-md shadow-sm'>
              <InputBase
                id={id}
                name={name}
                icon={icon}
                extraButton={extraButton}
                hasError={hasError}
                placeholder={placeholder}
                label={label}
                Trailing={Trailing}
                {...rest}
              />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <label
            htmlFor={id}
            className='block text-sm font-medium leading-5 text-gray-700'
          >
            {label}
          </label>
          <div className='mt-1 flex rounded-md shadow-sm'>
            <div className='relative flex-grow focus-within:z-10'>
              <InputBase
                id={id}
                name={name}
                icon={icon}
                extraButton={extraButton}
                hasError={hasError}
                placeholder={placeholder}
                label={label}
                Trailing={Trailing}
                {...rest}
              />
            </div>
            {extraButton}
          </div>
        </React.Fragment>
      )}

      {helperText ? (
        <p
          className={`mt-2 text-sm ${hasError ? 'text-red-600' : null}`}
          id='email-error'
        >
          {helperText}
        </p>
      ) : null}
    </div>
  )
}

export default InputField
