import React from 'react'

const CheckboxItem = ({ formik, name, label, value }) => {
  const isActive = Boolean(formik.values[name].includes(value))
  const handleChange = (event) => {
    const values = formik.values[name] || []
    const index = values.indexOf(value)
    if (index === -1) {
      values.push(value)
    } else {
      values.splice(index, 1)
    }
    formik.setFieldValue(name, values)
  }

  return (
    <div
      className={`relative border ${
        !isActive ? 'border-gray-200' : ''
      } first-child:rounded-tl-md first-child:rounded-tr-md p-4 flex last-child:rounded-bl-md last-child:rounded-br-md`}
    >
      {/* <!-- On: "bg-orange-50 border-orange-200 z-10", Off: "border-gray-200" --> */}
      <div className='flex items-center h-5'>
        <input
          id={value}
          name={name}
          type='checkbox'
          className='form-checkbox h-4 w-4 text-orange-600 transition duration-150 ease-in-out cursor-pointer'
          onChange={handleChange}
          checked={(formik.values?.[name] || [])?.indexOf(value) !== -1}
        />
      </div>
      <label htmlFor={value} className='ml-3 flex flex-col cursor-pointer'>
        {/* <!-- On: "text-orange-900", Off: "text-gray-900" --> */}
        <span className='block text-sm leading-5 font-medium'>{label}</span>
        {/* <!-- On: "text-orange-700", Off: "text-gray-500" --> */}
        {/* <span className='block text-sm leading-5'>
          This project would be available to anyone who has the link
        </span> */}
      </label>
    </div>
  )
}

const MultiSelect = ({ formik, label, name, options }) => {
  return (
    <fieldset className='mb-4'>
      <label
        htmlFor={name}
        className='block text-sm leading-5 font-medium text-gray-700'
      >
        {label}
      </label>

      <div className='bg-white rounded-md -space-y-px mt-1'>
        {(options || []).map((o) => {
          return (
            <CheckboxItem
              key={o.value}
              formik={formik}
              name={name}
              label={o.label}
              value={o.value}
            />
          )
        })}
      </div>
    </fieldset>
  )
}

export default MultiSelect
