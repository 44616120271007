import React from 'react'
import ConfusedIcon from './Assets/ConfusedIcon'
import HappyIcon from './Assets/HappyIcon'
import SadIcon from './Assets/SadIcon'
import SmileIcon from './Assets/SmileIcon'
import StarIcon from './Assets/StarIcon'
import VerySadIcon from './Assets/VerySadIcon'
import Alert from './Common/Alert'

const RatingField = ({
  label = 'Evalúanos',
  description = '',
  maxRange = 5,
  icon = 'star',
  color = 'yellow',
  value = 0,
  onChange,
  hasError
}) => {
  const [hovered, setHovered] = React.useState(null)

  const getRatingIcons = (idx) => {
    if (icon === 'star') {
      return <StarIcon className='mx-1' />
    }

    const facesArr = [VerySadIcon, SadIcon, ConfusedIcon, HappyIcon, SmileIcon]
    if (maxRange < 5) {
      facesArr.splice(1, 1)
      facesArr.splice(2, 1)
    }

    const Component = facesArr[idx]

    return <Component />
  }

  return (
    <div>
      <div className='block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2'>
        {label}
      </div>
      {description ? (
        <p className='mt-2 text-sm' id='description'>
          {description}
        </p>
      ) : null}
      <div className='flex justify-center items-center mb-4'>
        <div
          className='flex items-center mt-2 mb-4'
          onMouseLeave={() => setHovered(null)}
        >
          {Array.from(new Array(maxRange)).map((_, index) => {
            const isActive = hovered && hovered >= index + 1

            const commonClassName = `h-8 w-8 sm:h-14 sm:w-14 cursor-pointer`
            const starClassName = `${commonClassName} transform motion-safe:hover:-translate-y-1 motion-safe:hover:scale-110 transition ease-in-out duration-300 ${
              isActive || (value >= index + 1 && !hovered)
                ? `text-${color}-300 hover:text-${color}-400`
                : 'text-gray-300 hover:text-gray-400'
            }`
            const faceClassName = `mx-1 ${commonClassName} ${
              (hovered && hovered === index + 1) ||
              (value === index + 1 && !hovered)
                ? `rounded-full border-solid border-4 border-${color}-400`
                : ''
            }`

            return (
              <div
                key={index + 1}
                id={index + 1}
                className={icon === 'star' ? starClassName : faceClassName}
                onMouseEnter={(e) => {
                  setHovered(index + 1)
                }}
                onClick={() => {
                  if (value === index + 1) {
                    setHovered(null)
                    onChange(null)
                  } else {
                    onChange(index + 1)
                  }
                }}
              >
                {getRatingIcons(index)}
              </div>
            )
          })}
        </div>
      </div>
      {hasError ? <Alert message='Selecciona una valoración' /> : null}
    </div>
  )
}

export default RatingField
