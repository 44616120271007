import React from 'react'

const SmileIcon = () => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      style={{ enableBackground: 'new 0 0 512 512' }}
    >
      <circle style={{ fill: '#FFD93B' }} cx='256' cy='256' r='256' />
      <path
        style={{ fill: '#3E4347' }}
        d='M256,420.544c-74.864,0-140.096-50.704-158.624-123.28c-1.456-5.712,1.984-11.52,7.696-12.976
	c5.68-1.488,11.504,1.984,12.96,7.696c16.128,63.136,72.848,107.232,137.952,107.232s121.84-44.096,137.952-107.232
	c1.44-5.712,7.248-9.184,12.976-7.696c5.712,1.456,9.152,7.264,7.696,12.976C396.096,369.84,330.864,420.544,256,420.544z'
      />
      <path
        style={{ fill: '#F4C534' }}
        d='M512,256c0,141.44-114.64,256-256,256c-80.48,0-152.32-37.12-199.28-95.28
	c43.92,35.52,99.84,56.72,160.72,56.72c141.36,0,256-114.56,256-256c0-60.88-21.2-116.8-56.72-160.72
	C474.8,103.68,512,175.52,512,256z'
      />
      <ellipse
        style={{ fill: '#3E4347' }}
        cx='173.328'
        cy='220.304'
        rx='39.232'
        ry='46.624'
      />
      <path
        style={{ fill: '#5A5F63' }}
        d='M191.024,210.272c-3.056,2.4-8.08,1.216-11.296-2.688s-3.376-9.136-0.32-11.536
	c3.04-2.512,8.08-1.328,11.28,2.576C193.92,202.64,193.952,207.776,191.024,210.272z'
      />
      <ellipse
        style={{ fill: '#3E4347' }}
        cx='338.72'
        cy='220.304'
        rx='39.232'
        ry='46.624'
      />
      <path
        style={{ fill: '#5A5F63' }}
        d='M356.384,210.272c-3.056,2.4-8.08,1.216-11.296-2.688s-3.376-9.136-0.32-11.536
	c3.04-2.512,8.064-1.328,11.28,2.576C359.28,202.64,359.312,207.776,356.384,210.272z'
      />
    </svg>
  )
}

export default SmileIcon
