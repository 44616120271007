import React from 'react'

const ConfusedIcon = () => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      style={{ enableBackground: 'new 0 0 512 512' }}
    >
      <circle style={{ fill: '#FFD93B' }} cx='256' cy='256' r='256' />
      <path
        style={{ fill: '#F4C534' }}
        d='M512,256c0,141.44-114.64,256-256,256c-80.48,0-152.32-37.12-199.28-95.28
	c43.92,35.52,99.84,56.72,160.72,56.72c141.36,0,256-114.56,256-256c0-60.88-21.2-116.8-56.72-160.72
	C474.8,103.68,512,175.52,512,256z'
      />
      <g>
        <path
          style={{ fill: '#3E4347' }}
          d='M370.56,344.4c0,7.696-6.224,13.92-13.92,13.92H155.344c-7.616,0-13.92-6.224-13.92-13.92
		s6.304-13.92,13.92-13.92H356.64C364.352,330.496,370.56,336.704,370.56,344.4z'
        />
        <circle
          style={{ fill: '#3E4347' }}
          cx='174.512'
          cy='207.088'
          r='38.144'
        />
      </g>
      <ellipse
        transform='matrix(-0.597 -0.8023 0.8023 -0.597 150.8425 458.814)'
        style={{ fill: '#5A5F63' }}
        cx='190.667'
        cy='191.518'
        rx='10.016'
        ry='6.48'
      />
      <circle style={{ fill: '#3E4347' }} cx='337.44' cy='207.088' r='38.144' />
      <ellipse
        transform='matrix(-0.597 -0.8022 0.8022 -0.597 411.0304 589.5384)'
        style={{ fill: '#5A5F63' }}
        cx='353.586'
        cy='191.533'
        rx='10.016'
        ry='6.48'
      />
    </svg>
  )
}

export default ConfusedIcon
