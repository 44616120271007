import React from 'react'
import Slider from './components/Slider'
import { Reducer } from './reducers'
import Wrapper from './components/Wrapper'

export const PromoProvider = ({ awsConfig, options, children }) => {
  return (
    <Reducer>
      <Wrapper awsConfig={awsConfig} options={options}>
        {children}
      </Wrapper>
    </Reducer>
  )
}

export { Slider }
