import React from 'react'

const Loading = () => {
  return (
    <div className='fixed z-10 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity'>
          <div className='absolute inset-0 bg-gradient-to-r from-red-600 via-orange-400 to-yellow-300' />
        </div>
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' />
        &#8203;
        <div className='inline-block align-bottom px-4 pt-5 pb-4 text-center overflow-hiddentransform transition-all sm:my-8 sm:align-middle'>
          <svg
            className='animate-spin h-12 w-12 text-gray-200'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            />
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Loading
