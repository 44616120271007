import React from 'react'

const VerySadIcon = () => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      style={{ enableBackground: 'new 0 0 512 512' }}
    >
      <circle style={{ fill: '#FFD93B' }} cx='256' cy='256' r='256' />
      <path
        style={{ fill: '#F4C534' }}
        d='M512,256c0,141.44-114.64,256-256,256c-80.48,0-152.32-37.12-199.28-95.28
	c43.92,35.52,99.84,56.72,160.72,56.72c141.36,0,256-114.56,256-256c0-60.88-21.2-116.8-56.72-160.72
	C474.8,103.68,512,175.52,512,256z'
      />
      <g>
        <path
          style={{ fill: '#3E4347' }}
          d='M354.592,407.184c-12.736,7.68-47.008-26.496-94.24-26.912
		c-46.544-0.416-81.504,34.608-94.24,26.912c-15.248-6.208,10.832-80.608,94.24-81.056
		C343.76,326.576,369.84,400.992,354.592,407.184z'
        />
        <path
          style={{ fill: '#3E4347' }}
          d='M390.336,221.52c0,22.544-18.448,40.992-41.088,40.992c-22.656,0-41.088-18.448-41.088-40.992
		c0-22.752,18.448-41.088,41.088-41.088C371.904,180.432,390.336,198.768,390.336,221.52z'
        />
      </g>
      <ellipse
        transform='matrix(-0.7551 -0.6556 0.6556 -0.7551 503.256 596.3099)'
        style={{ fill: '#5A5F63' }}
        cx='363.001'
        cy='204.161'
        rx='12.112'
        ry='7.712'
      />
      <path
        style={{ fill: '#3E4347' }}
        d='M203.84,221.52c0,22.544-18.448,40.992-41.088,40.992s-41.088-18.448-41.088-40.992
	c0-22.752,18.448-41.088,41.088-41.088C185.392,180.432,203.84,198.768,203.84,221.52z'
      />
      <ellipse
        transform='matrix(-0.7551 -0.6556 0.6556 -0.7551 175.8983 474.0588)'
        style={{ fill: '#5A5F63' }}
        cx='176.489'
        cy='204.177'
        rx='12.112'
        ry='7.712'
      />
    </svg>
  )
}

export default VerySadIcon
