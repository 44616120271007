export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        restaurantID
        title
        description
        slug
        activateRewards
        public
        rewardPlace
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        steps {
          nextToken
          startedAt
        }
        questions {
          nextToken
          startedAt
        }
        responses {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      restaurantID
      title
      description
      slug
      activateRewards
      public
      rewardPlace
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      steps {
        items {
          id
          surveyID
          order
          title
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      questions {
        items {
          id
          stepID
          surveyID
          order
          title
          description
          status
          questionType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      responses {
        items {
          id
          restaurantID
          surveyID
          orderID
          waiter
          userID
          email
          phoneNumber
          values
          reward
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`
export const surveybyRestaurantbySlug = /* GraphQL */ `
  query SurveybyRestaurantbySlug(
    $restaurantID: String
    $slug: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveybyRestaurantbySlug(
      restaurantID: $restaurantID
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantID
        title
        description
        slug
        activateRewards
        public
        rewardPlace
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        steps(filter: { status: { eq: ACTIVE } }) {
          nextToken
          startedAt
          items {
            id
            surveyID
            order
            title
            description
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            questions(filter: { status: { eq: ACTIVE } }) {
              nextToken
              startedAt
              items {
                id
                stepID
                surveyID
                order
                title
                description
                status
                questionType
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                options(filter: { status: { eq: ACTIVE } }) {
                  items {
                    id
                    questionID
                    order
                    title
                    value
                    description
                    range
                    icon
                    status
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  nextToken
                  startedAt
                }
              }
            }
          }
        }
        questions(filter: { status: { eq: ACTIVE } }) {
          nextToken
          startedAt
          items {
            id
            stepID
            surveyID
            order
            title
            description
            status
            questionType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            options {
              items {
                id
                questionID
                order
                title
                value
                description
                range
                icon
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`

export const getStep = /* GraphQL */ `
  query GetStep($id: ID!) {
    getStep(id: $id) {
      id
      surveyID
      order
      title
      description
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      survey {
        id
        restaurantID
        title
        description
        slug
        activateRewards
        public
        rewardPlace
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        steps {
          nextToken
          startedAt
        }
        questions {
          nextToken
          startedAt
        }
        responses {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listSteps = /* GraphQL */ `
  query ListSteps(
    $filter: ModelStepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        surveyID
        order
        title
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        survey {
          id
          restaurantID
          title
          description
          slug
          activateRewards
          public
          rewardPlace
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`

export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stepID
        surveyID
        order
        title
        description
        status
        questionType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        survey {
          id
          restaurantID
          title
          description
          slug
          activateRewards
          public
          rewardPlace
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        options {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      stepID
      surveyID
      order
      title
      description
      status
      questionType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      survey {
        id
        restaurantID
        title
        description
        slug
        activateRewards
        public
        rewardPlace
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        steps {
          nextToken
          startedAt
        }
        questions {
          nextToken
          startedAt
        }
        responses {
          nextToken
          startedAt
        }
      }
      options {
        items {
          id
          questionID
          order
          title
          value
          description
          range
          icon
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`

export const getOption = /* GraphQL */ `
  query GetOption($id: ID!) {
    getOption(id: $id) {
      id
      questionID
      order
      title
      value
      description
      range
      icon
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      question {
        id
        stepID
        surveyID
        order
        title
        description
        status
        questionType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        survey {
          id
          restaurantID
          title
          description
          slug
          activateRewards
          public
          rewardPlace
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        options {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listOptions = /* GraphQL */ `
  query ListOptions(
    $filter: ModelOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionID
        order
        title
        value
        description
        range
        icon
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        question {
          id
          stepID
          surveyID
          order
          title
          description
          status
          questionType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getResponse = /* GraphQL */ `
  query GetResponse($id: ID!) {
    getResponse(id: $id) {
      id
      restaurantID
      surveyID
      orderID
      waiter
      userID
      email
      phoneNumber
      values
      reward
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      survey {
        id
        restaurantID
        title
        description
        slug
        activateRewards
        public
        rewardPlace
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        steps {
          nextToken
          startedAt
        }
        questions {
          nextToken
          startedAt
        }
        responses {
          nextToken
          startedAt
        }
      }
    }
  }
`
