import React from 'react'
import AWSAppSyncClient from 'aws-appsync'
import useReducer from '../reducers'

const useConfig = (awsConfig, options) => {
  const [, dispatch] = useReducer()

  React.useEffect(() => {
    const bootstrap = async () => {
      const appsync = new AWSAppSyncClient(awsConfig)
      const client = await appsync.hydrated()

      dispatch({ type: 'SURVEY_SET_CLIENT', payload: { client } })
      dispatch({ type: 'LOADING_APP_COMPLETED' })
    }

    if (awsConfig) {
      // Amplify.configure(awsConfig)
      // window.LOG_LEVEL = 'DEBUG'

      if (options && options.cdn) {
        dispatch({ type: 'SURVEY_SET_CDN', payload: { cdn: options.cdn } })
      }
      bootstrap()
    }
  }, [])
}

export default useConfig
