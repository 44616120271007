import useConfig from '../hooks/useConfig'
import useReducer from '../reducers'

const Wrapper = ({ awsConfig, options, children }) => {
  useConfig(awsConfig, options)
  const [
    {
      loadingReducer: { loadingApp }
    }
  ] = useReducer()

  if (loadingApp) {
    return null
  }

  return children
}

export default Wrapper
