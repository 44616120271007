const initialState = {
  client: null,
  cdn: null,
  survey: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SURVEY_SET_CLIENT':
      return {
        ...state,
        client: action.payload.client
      }
    case 'SURVEY_SET_CDN':
      return {
        ...state,
        cdn: action.payload.cdn
      }
    case 'SURVEY_SET':
      return {
        ...state,
        survey: action.payload.survey
      }
    default:
      return state
  }
}

export default { initialState, reducer }
