import React from 'react'

const TextareaField = ({
  id,
  name,
  label,
  placeholder,
  helperText,
  hasError,
  ...rest
}) => {
  return (
    <div className='mt-0'>
      <label
        htmlFor={id}
        className='block text-sm leading-5 font-medium text-gray-700'
      >
        {label}
      </label>
      <div className='rounded-md shadow-sm'>
        <textarea
          id={id}
          rows='3'
          className={`form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${
            hasError
              ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red'
              : null
          }`}
          placeholder={placeholder}
          {...rest}
        />
      </div>
      <p className='mt-2 text-sm text-gray-500'>{helperText}</p>
    </div>
  )
}

export default TextareaField
